import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import Navigation from "../components/Navigation/Navigation";
import Main from "../components/Main/Main";
import Content from "../components/Content/Content";
import Title from "../components/Title/Title";
import Page from "../components/Page/Page";
import Footer from "../components/Footer/Footer";

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <Layout>
      <Navigation />
      <Main>
        <Content>
          <Title title="contact" />
          <Page>
            <form
              action="https://formspree.io/xyyknaqj"
              method="POST"
              className="was-validated col-10 mx-auto h-100"
            >
              <div className="form-group h-100 my-5 py-5">
                <div className="form-row mb-3">
                  <div className="col-sm-6 mb-3 mb-md-0">
                    <label htmlFor="user_name">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control border border-dark- bg-light"
                      id="user_name"
                      placeholder="Your name"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="user_email">Email</label>
                    <input
                      type="text"
                      name="_replyto"
                      className="form-control border border-dark- bg-light"
                      id="user_email"
                      placeholder="someone@youremail.com"
                      required
                    />
                  </div>
                </div>

                <div id={`contactMessage`} className="mb-3">
                  <label htmlFor="user_mssg_textarea">Message</label>
                  <textarea
                    name="message"
                    className="form-control is-invalid border border-dark- bg-light"
                    id="user_mssg_textarea"
                    placeholder="What's on your mind?"
                    required
                  ></textarea>
                  <input className="d-none" type="text" name="_gotcha"/>
                </div>

                <div className="form-row">
                  <input
                    type="submit"
                    value="Send"
                    className="btn btn-dark m-auto py-3 px-4"
                  />
                </div>
              </div>
            </form>
          </Page>
        </Content>
        <Footer />
      </Main>
    </Layout>
  </>
);

export default ContactPage;
